body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.date {
  background-color: #e9e5f3;
  border-radius: 20px;
  outline: none;
  width: 370px;
  border: 1px solid #25a1b7;
}

.time {
  background-color: #e9e5f3;
  border-radius: 20px;
  outline: none;
  width: 370px;
  border: 1px solid #25a1b7;
}

.note {
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 70px;
  border: 2px solid #25a1b7;
  margin: 2px;
}

.comment {
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 30px;
  border: 1px solid #157ffb;
  margin: 2px;
}

.comment1 {
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 70px;
  border: 1px solid #157ffb;
  margin: 2px;
}

.CitaSeleccionada {
  color: black;
  padding-left: 5px;
}

.apagado {
  display: none;
}

.fondoCita {
  margin-top: 57px;
  background: rgba(233, 229, 243, 0.8);
  border-radius: 10px;
  height: 400px;
  outline: none;
  border: 3px solid #25a1b7;
}

.absolute {
  position: absolute;
  top: 200px;
}

.absolute2 {
  position: absolute;
  top: 200px;
}

.sinhoras {
  animation: blinkingText 1.5s infinite;
}

@keyframes blinkingText {
  0% {
    color: red;
  }

  50% {
    color: transparent;
  }
}

.citas {
  background-color: #e9e5f3;
  opacity: 0.8;
}

.table td {
  margin-bottom: 0;
  padding: 0.15rem;
}

.lista {
  font-size: 15px;
}

.absolute3 {
  position: sticky;
  top: 0;
  background-color: white;
}

.logo {
  height: 60px;
  width: auto;
  border-radius: 30%;
}

.menu {
  display: flex;
}

.links {
  display: flex;
}

.alineacion {
  margin: 4px;
  font-size: 18px;
  color: #25a1b7;
}

.alineacion:hover {
  font-size: 18px;
  font-weight: bold;
}
#sesion{
  background-color: white;
  color: #007bff;
  border-color: #4972f5;
}
.especialidades{
  font-size: 33px;
  font-family: 'Antic Slab', serif;
  margin-left: 15px;
  margin-bottom: 26px;
}
.especiFoto{
  margin-top: 43px;
  margin-left: 9px;
  height: 55px;
  width: auto;
}
.especiCard{
  max-width: 400px;
  height: auto;
  box-shadow: rgb(0 0 0 / 20%) 4px 4px 8px;
  break-inside: avoid;
  margin-left: 15px;
  margin-bottom: 2em;
}
.especititle{
  font-size: 23px;
  font-family: 'Antic Slab', serif;
}
.especitext{
  font-size: 17px;
  font-family: 'Antic Slab', serif;
}
.especiBody{
  margin-left: 18px;
}
.especiLink{
  float: right;
  margin-bottom: 7px;
}
.faq {
  border: #4972f5;
  border-width: medium;
  border-style: solid;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.h1 {
  margin: 30px;
}

.Calendar {
  font-size: 11px;
  box-shadow: none;
  border-style: solid;
  border-color: #25a1b7;
  background: rgba(233, 229, 243, 0.8);
}

.padCal {
  margin-top: 4%;
  padding-left: 50px;
}

.Calendar__weekDay {
  color: black;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
}

.off {
  display: none;
}

#todas {
  margin-top: 2%;
  height: 35px;
  width: 250px;
}

.max {
  max-width: 4vw;
}

.min {
 width: 7vw;         
}

.fechaActual {
  display: flex;
  justify-content: space-around;
}

#searchText {
  border-style: solid;
  border-color: #25a1b7;
  padding: 6px;
}

.scrollbar {
  overflow: scroll;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

/* custom colors  */
.scrollbar-info {
  scrollbar-color: #33b5e5 #f5f5f5;
}

.scrollbar-info::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 6px;
}

.scrollbar-info::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.scrollbar-info::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #33b5e5;
}

.headerSchedule {
  margin-bottom: 30px;
}

.imagen {
  width: 30%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.texto {
  text-align: center;
}

.seccion2 {
  margin: 35px;
}

.card {
  border: none;
}

.clearboth {
  text-align: center;
  font-size: 35px;
}

.titulo {
  font-size: 35px;
  margin-left: 150px;
}

.Foto {
  width: 100%;
  height: auto;
}

.odontograma {
  width: 70%;
  margin: 20px;
}

.letraExpediente {
  font-weight: bold;
}

.Foto2 {
  width: 100%;
  height: auto;
}

.pregunta {
  text-align: center;
  padding: 35px 15px 15px 15px;
}

.respuesta {
  text-align: justify;
  padding: 5px 55px 15px 55px;
}

.reg {
  text-align: center;
  /* EL margen de abajo no aplica por la clase mb-4*/
}

.form-regis {
  margin-top: 50px;
  margin-bottom: 50px;
}
.camposForm{
  border-color: black;
}
#start {
  background-color: #ccab85;
  background-size: cover;
  height: 600px;
}

.seccion1 {
text-align: center;
align-content: center;
}

.consultorio{
margin-top: 110px;
margin-bottom: 0px;
font-size: 60px;
color: white;
font-family: 'Antic Slab', serif;
}

/* .texto1 {
  padding: 150px 0 0;
} */

.texto2 {
font-size: 27px;
color: white;
font-family: 'Antic Slab', serif;
margin-bottom: 200px;
}
.segundoCard{
  background-color: #188ac7;
  border-color: #188ac7;
  color: white;
}
.tercerCard{
  background-color: #45b8ed;
  border-color: #45b8ed;
  color: white;
}
.horarios{
color: white;
font-size: 17px;
}
.cardTitle{
  float: left;
  font-size: 21px;
}
.cardtext{
  text-align: left;
  font-size: 17px;
}
.botonturnos{
  float: right;
  background-color: white;
}
.bienvenido {
  text-align: left;
  color: black;
  padding: 0 0 30px 40px;
}

.responsive-calendar {
  /* by setting font-size, all the elements will correspond */
  font-size: 9px !important;
  /* default to 10px */
}

@media (max-width: 1500px) {
  .responsive-calendar {
    font-size: 8px !important;
  }
}

@media (max-width: 1200px) {
  .responsive-calendar {
    font-size: 7px !important;
  }
}

@media (max-width: 768px) {
  .responsive-calendar {
    font-size: 6px !important;
  }
}

/* Large screens */
@media (min-width: 2500px) {
  .responsive-calendar {
    font-size: 12px !important;
  }
}

select {
  background-color: #25a1b7;
  color: #ffffff;
  padding: 8px 16px;
  border: 5px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

.alldat {
  margin-top: 23px;
}

.marginWebCirculos {
  margin: 21px 15px 15px 15px;
}

.margin-horasPorDia {
  margin-top: 20px;
}

.marginMovilCirculos {
  margin: 5px;
  margin-top: 15px;
}

.marginBlockApointment {
  margin-top: 8px;
}

.buscador {
  margin-left: 15px;
}

input {
  border: 2px solid;
  border-radius: 4px;
  font-size: 1rem;
  margin: 0.25rem;
  min-width: 125px;
  padding: 0.5rem;
  transition: border-color 0.5s ease-out;
}

input:optional {
  border-color: gray;
}

input:required:valid {
  border-color: limegreen;
}

input:invalid {
  border-color: pink;
}

input:required:focus:valid {
  background: url(./Imagenes/check-solid.svg) no-repeat 95% 50% rgb(234, 251, 233);
  background-size: 17px;
}

input:focus:invalid {
  background: url(./Imagenes/exclamation-solid.svg) no-repeat 95% 50% rgb(255, 227, 227);
  background-size: 7px;
}

.edit {
  margin: 4px;
  font-size: 35px;
  color: #25a1b7;
}

.edit:hover {
  font-size: 35px;
  font-weight: bold;
}

.dropdown-toggle {
  color: #25a1b7;
}

.dropdown-toggle:hover {
  font-weight: bold;
  color: #25a1b7;
}

.w3-input {
  width: 10%;
  max-width: 20%;
  outline-color: #25a1b7;
  margin-bottom: 10px;
}

.bold {
  font-weight: bold;
}

.sticky {
  position: sticky;
  top: 90%;
  text-align: right;
}

.float {
  float: right;
}

.hours {
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 30px;
  border: 1px solid #25a1b7;
  margin: 1px;
}

.hours1 {
  margin-top: 4px;
  margin-left: 5px;
  font-size: 14px;
}

.margin {
  margin: 15px;
}

.floatl {
  float: left;
}

.nomargin {
  margin: 0;
}

.del {
  color: red;
  background-color: transparent;
  margin: 0;
  border: none;
  display: inline;
  float: right;
}

.headerSchedule {
  margin-bottom: 10px;
}

.row {
  margin-left: 0;
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
}

.fixed {
  position: fixed;
  right: 1px;
  top: 100%;
}

.imgcontact {
  margin-top: 50px;
  height: auto;
  width: 100%;
}

.mailcontact {
  text-align: center;
  margin-top: 20px;
}

.actual {
  margin-bottom: 9px;
}

.whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 10px;
  background-color: #fff;
  color: #25d366;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 13px;
}

.logom {
  height: 40px;
  width: auto;
  border-radius: 30%;
}

.center {
  text-align: center;
  margin: 8px;
}

.social {
  font-size: 13px;
  background-color: #25a1b7;
  color: #fff;
  padding: 15px;
}

.social:hover {
  background-color: #157ffb;
}

.fa-user:before {
  content: "\f007";
  font-size: 25px;
}

.delete {
  margin: 4px;
}

/* Esto mantiene el footer abajo */
html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%;
}

.content {
  margin: 0 auto;
  padding-top: 64px;
  max-width: 640px;
  width: 94%;
  font-family: "Raleway", sans-serif;
  line-height: 30px;
}

.content h1 {
  margin-top: 0;
}

/**
 * Footer Styles
 */
.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: #f8f9fa;
  text-align: center;
  color: #fff;
}

.d-flex {
  display: flex;
  justify-content: space-between;
}


/*
End Footer Styles
*/
.odontologosCards{
  min-height: 200px;
  max-height: 200px;
}