.filtrosFila {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 2vh;
}

.filtosColumna {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.picker {
    z-index: 1000;
    color: rgb(34, 145, 168);
    border-radius: 0;
    border-width: 1px 1px 1px 1px;
    font-weight: 550;
}

.select {
    margin-left: 1px;
    color: rgb(34, 145, 168);
    border-radius: 0;
    border-width: 1px 1px 1px 1px;
    font-weight: 550;
    border-color: rgb(34, 145, 168);
}

.margin {
    margin: 10px;
}

.centrado {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    .btns {
        display: flex;
        flex-direction: column;
        flex-grow: 4;
        /* default 0 */
        flex-wrap: wrap;
        margin: 10px;
    }
}