@media print {
  body * {
    visibility: hidden;
    position: absolute;
    overflow: visible;
    height: auto;
    width: auto;
  }

  #printSection,
  #printSection * {
    visibility: visible;
    position: static;
  }

  #printSection {
    position: absolute;
    left: 1;
    top: 0;
    right: 1;
    bottom: 0;
    overflow: visible;
  }

  #printSection span {
    /* font-size: 18px !important; */
  }
}

.fila {
  display: flex;  
  justify-content: space-between;
}


.filaAbajo{
  display: flex;
  /* Que esten space around pero empiezen alineadas */
  justify-content: space-around;
  align-items: center;
}

.borderEnorme {
  border-bottom: 1px solid gray;
  padding: 5px;
  min-width: 900px;
}

.borderGde {
  border-bottom: 1px solid gray;
  padding: 5px;
  min-width: 300px;
}

.borderGdeVacio {
  border-bottom: 1px solid gray;
  padding: 5px;
  min-width: 600px;
}

.borderChico {
  border-bottom: 1px solid gray;
  padding: 5px;
  min-width: 200px;
}

.altura{
  height: 260px;
}

.margen{
  margin-top: 15px;
}

.fotoCentrada{
  display: flex;
  justify-content: center;
}
.letra{
  font-size: 13px;
}